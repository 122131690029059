<template>
  <b-card>
    <div>
      <validation-observer
        ref="accountRules"
        tag="form"
      >
        <b-form
          class="mt-1"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Mata Pelajaran
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Kode"
                label-for="code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
                >
                  <b-form-input
                    id="code"
                    v-model="dataParent.code"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Kode Mata Pelajaran"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Mata Pelajaran"
                label-for="subject"
              >
                <validation-provider
                  #default="{ errors }"
                  name="subject"
                  rules="required"
                >
                  <b-form-input
                    id="subject"
                    v-model="dataParent.subject"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Mata Pelajaran"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Aktif"
                label-for="active"
              >
                <validation-provider
                  #default="{ errors }"
                  name="active"
                  rules="required"
                >
                  <b-form-checkbox
                    v-model="dataParent.active"
                    name="checkbox"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Deskripsi"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-input
                    id="description"
                    v-model="dataParent.description"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Deskripsi"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="formSubmitted"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="() => $router.go(-1)"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm, BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'

// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      email,
      size,
      dataParent: {
        code: '',
        subject: '',
        active: 0,
        description: '',
      },
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
      options: [
        { text: 'true', value: '1' },
        { text: 'false', value: '0' },
      ],
    }
  },
  computed: {
  },
  methods: {
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/academicsubjects', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Mata Pelajaran Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'mata-pelajaran' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Mata Pelajaran Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
